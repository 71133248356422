import thumbnailLesson1 from "./lesson1images/thumbnail.webp";
import problem1 from "./lesson1images/problem-1.webp";
import problem2 from "./lesson1images/problem-2.webp";
import problem3 from "./lesson1images/problem-3.webp";
import problem4 from "./lesson1images/problem-4.webp";
import problem5 from "./lesson1images/problem-5.webp";
import problem6 from "./lesson1images/problem-6.webp";
import problem7 from "./lesson1images/problem-7.webp";
import problem8 from "./lesson1images/problem-8.webp";
import problem9 from "./lesson1images/problem-9.webp";
import problem10 from "./lesson1images/problem-10.webp";
import problem11 from "./lesson1images/problem-11.webp";
import problem12 from "./lesson1images/problem-12.webp";

const lessonData = [
  {
    id: "course-1",
    title: "intro",
    slides: [
      { id: 0, image: thumbnailLesson1, alt: "Thumbnail", price: 0 },
      { id: 1, image: "slide1.jpg", alt: "Slide 1", text: "Text for slide 1" },
      { id: 2, image: "slide2.jpg", alt: "Slide 2", text: "Text for slide 2" },
    ],
  },
  {
    id: "course-2",
    title: "problem",
    slides: [
      { id: 0, image: problem1, alt: "Thumbnail", price: 15 },
      { id: 1, image: problem2, alt: "Problem slide 2", text: "Think about this quote from Einstein for a minute. What do you think he was getting at? Fundamentally the most important way to craft a successful business is to create value for your customers by solving a problem. Understanding your problem to the most intricate level will give you the greatest chance of tackling it in an innovative way. Take a moment to draft up common problems in your life, anything that frustrates you on a daily basis is a problem that other people are likely to share"},
      { id: 2, image: problem3, alt: "Problem slide 3", text: "Take a look at these products. Funny right, but why wouldn’t someone buy them. Because they DO NOT solve problems. They are simply novelty products." },
      { id: 3, image: problem4, alt: "Problem slide 4", text: "Take a moment to examine these problem statements. What do you think is wrong with them?"},
      { id: 4, image: problem5, alt: "Problem slide 5", text: "Climate change is too large of an issue to tackle as a solo entrepreneur"},
      { id: 5, image: problem6, alt: "Problem slide 6", text: "The books problem is interesting. It isn’t inherently a bad problem, but the solution is too narrow. The underlying problem is that I can’t carry enough books - not that a backpack isn’t big enough. Not understanding the problem like that could lead you to create a new backpack instead of finding a way to move books onto a digital tablet."},
      { id: 6, image: problem7, alt: "Problem slide 7", text: "Again, understanding your problem will lead to better solutions. Take this quote from Ford, the inventor of the car, for example. If Ford tried to focus on how vehicles moved around instead of the issue of transportation, he could not have invented the car."},
      { id: 7, image: problem8, alt: "Problem slide 8", text: "Here’s a great exercise for getting to the root of problems. It’s called the triangle of why. Let’s start with a problem many student entrepreneurs face in their lives - being stressed as a student"},
      { id: 8, image: problem9, alt: "Problem slide 9", text: "Beginning with the broad problem, and work our way up the pyramid by asking the simple question: why?"},
      { id: 9, image: problem10, alt: "Problem slide 10", text: "Ah - because we looked at why students are stressed, we’ve discovered another problem. Let’s continue"},
      { id: 10, image: problem11, alt: "Problem slide 11", text: ""},
      { id: 11, image: problem12, alt: "Problem slide 12", text: "So just by asking why, we’ve gained a better footing of our problem. Instead of trying to tackle “stress” we can now focus on creating a solution for the problem of disconnected teachers and students. Refer back to the problems you wrote down earlier and try asking why they exist until you find a more tackle-able problem"},

    ],
  },
  {
    id: "course-3",
    title: "customers",
    slides: [
      { id: 0, image: thumbnailLesson1, alt: "Thumbnail", price: 15 },
      { id: 1, image: "slide3.jpg", alt: "Slide 3", text: "Text for slide 3" },
      { id: 2, image: "slide4.jpg", alt: "Slide 4", text: "Text for slide 4" },
    ],
  },
];

export default lessonData;
